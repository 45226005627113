@import url(https://fonts.googleapis.com/css2?family=Abel&family=Roboto:wght@100&display=swap);
:root {
  font-size: 16px; }

#root {
  background-color: #eee; }

html {
  background-color: #eee; }

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: #ccc;
  padding: 0; }
  body::-webkit-scrollbar {
    width: 0.25rem; }
    body::-webkit-scrollbar-track {
      background: #1e1e24; }
    body::-webkit-scrollbar-thumb {
      background: #6649b8; }

.main-wrapper {
  font-family: 'Abel', sans-serif;
  font-weight: 500; }
  .main-wrapper h1 {
    text-align: center; }
  .main-wrapper .cards-wrapper .card {
    box-shadow: 0 0 20px 4px #555558; }
  .main-wrapper button.btn-primary, .main-wrapper a.btn-primary {
    background-color: #574b90;
    border: solid 1px #6649b8; }
    .main-wrapper button.btn-primary:hover, .main-wrapper a.btn-primary:hover {
      -webkit-filter: grayscale(30%) opacity(0.7);
              filter: grayscale(30%) opacity(0.7); }

.copy-right {
  position: fixed;
  width: 130px;
  right: 20px;
  bottom: 5px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #bbb;
  color: #eee;
  text-shadow: 0px 1px 1px black; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@media only screen and (min-width: 600px) {
  .desktop-only {
    display: block !important; } }

@media only screen and (max-width: 780px) {
  .desktop-only {
    display: none !important; } }

@media only screen and (min-width: 600px) {
  .mobile-only {
    display: none !important; } }

@media only screen and (max-width: 780px) {
  .mobile-only {
    display: block !important; } }

.table-responsive {
  box-shadow: 0 0 40px 4px #111118; }

.card {
  border: 1px solid #ececec; }

ul {
  list-style-type: none; }

.form-custom-group {
  display: flex;
  margin-bottom: 1rem;
  width: 100%; }
  .form-custom-group .label-name {
    flex: 20% 0; }
  .form-custom-group .custom-input {
    flex: 0 1 60%; }

div.form-custom-group-i {
  width: 50%;
  display: block;
  margin-bottom: 1rem;
  height: 3.5rem; }
  div.form-custom-group-i .custom-input.form-control {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    border: none; }
    div.form-custom-group-i .custom-input.form-control:focus {
      z-index: 0; }
  div.form-custom-group-i .label-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    margin: 0;
    pointer-events: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
    font-weight: 500; }
    div.form-custom-group-i .label-name::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 100%;
      width: 100%;
      border-bottom: 2px solid #5fa8d3; }

.main-wrapper h1 {
  margin-top: 3rem; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert.danger {
    color: #ac0f1e;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.success {
    color: #085E14;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.home h1 {
  font-size: 2rem;
  text-align: center; }

.home .home-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem; }

.home .user-list_wrapper {
  width: 60%;
  margin: 0 auto; }

.custom-table_wrapper .custom-table_container {
  margin-bottom: 1rem; }

.custom-table_wrapper .table-link-title {
  color: #000; }

.custom-table_wrapper .table-icon {
  margin-right: 5px; }

.custom-table_wrapper .custom-table_subtable {
  margin: 1rem 0 0 0; }

.custom-table_wrapper .custom-table_head {
  border-radius: 2px 0 2px 0;
  -moz-border-radius: 2px 0 2px 0;
  -webkit-border-radius: 2px 0 2px 0; }

.custom-table_wrapper .custom-table_header {
  background-color: #23232e; }
  .custom-table_wrapper .custom-table_header .MuiTableCell-root {
    color: #ececec; }

.custom-table_wrapper .MuiTableCell-root {
  padding: 0.5rem; }

.auth h1 {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.2; }

.auth h3 {
  color: #085E14;
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2; }

.auth .auth-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem; }
  @media only screen and (max-width: 780px) {
    .auth .auth-container.App {
      width: 100%;
      margin: 0 auto; } }

.auth .main-wrapper {
  font-family: "Red Hat Display", Sans-serif;
  font-weight: 500;
  color: #767676;
  font-size: 17px;
  padding-top: calc(0.375rem + 1px);
  line-height: 1.5; }

.auth .auth-list_wrapper .card-body {
  background-color: white;
  padding: 0rem; }

.auth .auth-list_wrapper {
  width: 60%;
  margin: 0 auto; }
  .auth .auth-list_wrapper .card-header {
    background-color: #23232e;
    color: #ececec;
    padding: 0.6rem;
    line-height: 1;
    border-radius: 4px 4px 0 0; }
  .auth .auth-list_wrapper .card-body {
    background-color: white;
    padding: 0rem; }
  .auth .auth-list_wrapper .auth-btn-sign,
  .auth .auth-list_wrapper .auth-btn-login {
    margin-top: 1rem; }
  .auth .auth-list_wrapper header.tab-wrapper-authentication-tabs {
    background: transparent;
    box-shadow: none; }
    .auth .auth-list_wrapper header.tab-wrapper-authentication-tabs button {
      color: #fff;
      font-weight: bold;
      border: 1px solid #000;
      border-radius: 4px 4px 0 0;
      background: #23232e; }

.auth .form-custom-group {
  flex-direction: column;
  align-items: center; }
  .auth .form-custom-group .label-name {
    display: block;
    text-align: left; }
  .auth .form-custom-group .p-component,
  .auth .form-custom-group .p-component * {
    width: auto;
    padding: 8px 16px;
    float: unset; }
    .auth .form-custom-group .p-component .personas:not(.p-disabled):not(.p-highlight):hover,
    .auth .form-custom-group .p-component * .personas:not(.p-disabled):not(.p-highlight):hover {
      background-color: #cccc33;
      border-radius: 39px;
      font-size: 18px;
      color: #006600;
      font-weight: 600; }
    .auth .form-custom-group .p-component .companys:not(.p-disabled):not(.p-highlight):hover,
    .auth .form-custom-group .p-component * .companys:not(.p-disabled):not(.p-highlight):hover {
      background-color: #006600;
      border-radius: 39px;
      font-size: 18px;
      color: #ffffff;
      font-weight: 600; }
  .auth .form-custom-group .p-button:not(.p-disabled):not(.p-highlight) {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
    border-radius: 39px;
    margin-right: 10px;
    font-weight: 600; }
  .auth .form-custom-group .p-selectbutton .p-button.p-highlight {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
    border-radius: 39px;
    margin-right: 10px;
    font-weight: 600; }

.auth .people .form-custom-group .p-component .personas {
  background-color: #cccc33 !important;
  border-radius: 39px;
  font-size: 18px;
  color: #006600;
  margin-right: 10px;
  font-weight: 600; }

.auth .company .form-custom-group .p-component .companys {
  background-color: #006600 !important;
  border-radius: 39px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600; }

.auth .form-title {
  text-align: left;
  border-bottom: 1px solid #ccc; }

.auth .form-container .form-text {
  padding-left: 1rem;
  font-family: "Red Hat Display", Sans-serif; }

.auth .form-container h2 {
  float: left;
  padding-bottom: 1rem;
  font-family: "Red Hat Display", Sans-serif; }

.auth .field-container .material-field div p {
  text-align: left;
  float: left;
  font-family: "Red Hat Display", Sans-serif;
  font-weight: 500;
  color: #767676;
  font-size: 17px;
  padding-top: calc(0.375rem + 1px);
  line-height: 1.5; }

.auth .custom-select {
  margin-top: 1rem;
  text-align: left; }

.auth .file-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .auth .file-container .custom-file {
    margin-left: -95px; }
  .auth .file-container .button-file {
    min-width: 120px; }

.auth h2 {
  color: #085E14;
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2; }

.auth .MuiSwitch-thumb {
  color: #085E14; }

.auth .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb {
  color: #95c21d; }

@media only screen and (max-width: 780px) {
  .auth .MuiStepLabel-labelContainer {
    display: none; } }

.auth .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  color: #555558;
  background-color: #555558; }

.auth .card,
.auth .card-body {
  background-color: #fff;
  width: 100%; }

.auth .card-body {
  align-items: center; }
  .auth .card-body .modaldiv .modalCities {
    background: transparent;
    -webkit-tap-highlight-color: transparent; }

.auth .form-custom-group {
  width: 100%;
  padding: 30px 0px 0px 0px;
  align-items: center; }

.auth auth .auth-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem;
  background-color: white; }

.personasBanner {
  height: 697.5px;
  background-color: #fff; }

.form-container .material-field .MuiFormControl-root.datepicker {
  margin-top: 4px; }

.form-container .instruccionC {
  align-items: center;
  padding: 1%; }
  .form-container .instruccionC .labelIns .labelInstruccion {
    margin-left: 1%;
    width: 96%;
    background-color: #fff;
    padding: 14px;
    border: 1px solid #008a17;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-family: "Red Hat Display";
    font-weight: 500;
    color: #4e4e4e;
    display: inline-block; }

.section-container .field-container .instruccionPQR .section-container {
  padding: 1%;
  border: solid 1.5px green;
  border-radius: 10px;
  margin: 0% 3% 2%; }
  .section-container .field-container .instruccionPQR .section-container .material-field {
    text-align: center;
    min-height: 0px;
    margin: 0%;
    padding: 0%; }
    .section-container .field-container .instruccionPQR .section-container .material-field .labelIns .labelInstruccion {
      padding: 5px 0px 0px 10px; }
      .section-container .field-container .instruccionPQR .section-container .material-field .labelIns .labelInstruccion .labelNeg {
        font-weight: bold;
        font-size: medium;
        margin: 0%;
        color: #008a17;
        padding: 0%; }
      .section-container .field-container .instruccionPQR .section-container .material-field .labelIns .labelInstruccion .labelNor {
        font-size: medium;
        margin: 0%;
        padding: 0%;
        color: #008a17; }
  .section-container .field-container .instruccionPQR .section-container .principal .labelIns .labelInstruccion {
    color: #008a17; }

.form-custom-group {
  display: flex;
  justify-content: space-between; }
  .form-custom-group .label-name {
    display: none; }
  .form-custom-group .custom-input {
    width: 100%; }
  .form-custom-group.spacer {
    min-height: 67px; }
    .form-custom-group.spacer.last {
      min-height: 68px; }
  .form-custom-group.rs-100 {
    max-width: 98%; }
  .form-custom-group.rs-75 {
    max-width: 73%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-60 {
    max-width: 58%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-50 {
    max-width: 48%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-30 {
    max-width: 28%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-40 {
    max-width: 38%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-25 {
    max-width: 23%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-20 {
    max-width: 18%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-15 {
    max-width: 13%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-100 .custom-input, .form-custom-group.rs-75 .custom-input, .form-custom-group.rs-60 .custom-input, .form-custom-group.rs-50 .custom-input, .form-custom-group.rs-40 .custom-input, .form-custom-group.rs-30 .custom-input, .form-custom-group.rs-25 .custom-input {
    flex: 0 1 100%; }
  @media only screen and (max-width: 780px) {
    .form-custom-group.rs-100, .form-custom-group.rs-75, .form-custom-group.rs-60, .form-custom-group.rs-50, .form-custom-group.rs-40, .form-custom-group.rs-30, .form-custom-group.rs-25 {
      max-width: 100%;
      margin-right: 0;
      float: left; } }
  .form-custom-group .MuiButton-containedPrimary {
    background-color: #085E14; }

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center; }
  .button-wrapper .MuiButton-outlinedPrimary {
    color: white;
    background-color: #085E14;
    border: none; }
    .button-wrapper .MuiButton-outlinedPrimary:hover {
      background-color: #95c21d;
      border: none; }
  .button-wrapper .MuiButton-outlinedSecondary {
    color: white;
    background-color: #23232e;
    border: none; }
    .button-wrapper .MuiButton-outlinedSecondary:hover {
      background-color: #555558;
      border: none; }

::-webkit-input-placeholder {
  /* Edge */
  font-size: 0.9rem; }

::placeholder {
  font-size: 0.9rem; }

body {
  font-family: "Red Hat Display", Sans-serif; }

p {
  display: block;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper {
  padding: 0rem;
  border: 0.5 px solid #ced4da;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .p-rating {
  width: 100%;
  float: left;
  text-align: LEFT;
  margin-top: 2rem;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .helptext {
  float: left;
  margin-right: 1rem; }

.form-wrapper .form-sub-title {
  text-align: left;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .table .form-child li {
  position: relative; }

.form-wrapper .table .form-child h4 {
  display: none; }

.form-wrapper .table .form-child .button-wrapper.delete {
  position: inherit;
  top: 0;
  margin: 0;
  float: right;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .table .form-child .field-container {
  display: -webkit-inline-box;
  width: 100%;
  overflow: scroll;
  grid-template-columns: repeat(12, 1fr);
  margin: 0;
  padding: 20px 10px;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .table .form-child .field-container .material-field {
  margin-left: 0px;
  font-size: 0.9rem;
  min-height: 2rem;
  font-family: "Red Hat Display", Sans-serif; }
  .form-wrapper .table .form-child .field-container .material-field .MuiFormControl-root.MuiInputBase-root.Mui-error {
    border-color: red; }

.form-wrapper .table .form-child .field-container .material-field .MuiTableHead-root {
  display: table-header-group;
  background: antiquewhite;
  font-family: "Red Hat Display";
  font-weight: bold;
  /* position: unset; */ }

.form-wrapper .table .form-child .field-container .material-field .label-name {
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  color: #292929;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
  font-family: "Red Hat Display", Sans-serif; }

.form-wrapper .table .form-child .field-container .material-field label,
.form-wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
  font-size: 0.8rem;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: unset;
  width: 120px;
  font-weight: bold; }

.form-wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-family: "Red Hat Display", Sans-serif;
  padding-top: 24px; }

.form-container {
  border: none; }

.form-container.spacer {
  min-height: 67px; }

.form-container.spacer.last {
  min-height: 68px; }

.form-container .form-container .label-name {
  float: left;
  font-family: "Red Hat Display", Sans-serif;
  font-weight: 500;
  color: #767676;
  font-size: 15px;
  padding-top: calc(0.375rem + 1px);
  line-height: 1.5;
  background-color: #fff; }

.form-container .field-container {
  display: inline-block;
  height: auto;
  width: 100%;
  margin-bottom: 1rem; }

.form-container .label-name {
  float: left; }

.form-container .material-field {
  min-height: 5.6rem; }

.form-container .material-field .MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.1rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
          user-select: none; }

.form-container .material-field .MuiFormControl-root {
  width: 100%; }

.form-container .material-field .MuiFormControl-root .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.form-container .material-field .MuiFormControl-root .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  color: #000;
  font-size: 12px;
  font-family: "Red Hat Display";
  font-weight: 500;
  padding: 0px; }

.form-container .material-field .MuiFormControl-root .MuiAutocomplete-inputRoot:focus {
  padding-right: 0px;
  font-family: "Red Hat Display", Sans-serif;
  border: #085e14 solid 0.8px;
  color: #767676;
  border-color: #95c21d;
  outline: 0;
  box-shadow: 0 0 0.125rem 0.25rem rgba(203, 212, 31, 0.5);
  background-color: #fff; }

.form-container .material-field .MuiFormControl-root .MuiAutocomplete-root {
  font-family: "Red Hat Display", Sans-serif;
  color: currentColor;
  width: 100%;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-animation-name: mui-auto-fill-cancel;
          animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent; }

.form-container .material-field .MuiFormControl-root .MuiAutocomplete-root .MuiAutocomplete-input:first-child {
  padding: 6px 15px;
  height: 26px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Red Hat Display"; }

.form-container .material-field .MuiFormControl-root .MuiAutocomplete-root .MuiAutocomplete-input.Mui-focused {
  padding: 6px 7px;
  height: 26px;
  box-shadow: 0 0 0.125rem 0.25rem #fff; }

.form-container .material-field .MuiFormControl-root .MuiFormGroup-root {
  flex-wrap: wrap;
  flex-direction: column;
  display: -webkit-inline-box; }

.form-container .material-field .MuiFormControl-root label + .MuiInput-formControl {
  margin-top: 4px;
  font-family: "Red Hat Display", Sans-serif; }

.form-container .material-field .MuiFormControl-root .p-progress-spinner {
  position: initial;
  margin: 0;
  width: opx;
  height: 0px;
  display: inline-block; }

.form-container .material-field .MuiFormControl-root .MuiCheckbox-colorSecondary.Mui-checked {
  color: #085e14; }

.form-container .material-field.rs-100 {
  width: 100%;
  float: left; }

.form-container .material-field.rs-100-2 {
  width: 98%;
  margin-left: 1%;
  float: left; }

.form-container .material-field.rs-90 {
  width: 88%;
  margin: auto;
  -webkit-margin-after: 1%;
          margin-block-end: 1%; }

.form-container .material-field.rs-80 {
  width: 78%;
  margin-left: 1%;
  margin-right: 1%;
  -webkit-margin-after: 1%;
          margin-block-end: 1%;
  float: left; }

.form-container .material-field.rs-75 {
  width: 73%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-70 {
  width: 68%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-60 {
  width: 58%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-50 {
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-30 {
  width: 28%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-40 {
  width: 38%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-40-2 {
  width: 38%;
  margin-left: 1%;
  margin-right: 1%;
  margin: auto;
  -webkit-margin-after: 1%;
          margin-block-end: 1%; }

.form-container .material-field.rs-25-2 {
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-25 {
  width: 22%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-20 {
  width: 18%;
  margin-left: 1%;
  margin-right: 1%;
  float: left; }

.form-container .material-field.rs-15 {
  width: 13%;
  margin-right: 1%;
  margin-left: 1%;
  float: left; }

.form-container .material-field.rs-10 {
  width: 8%;
  margin-right: 1%;
  margin-left: 1%;
  float: left; }

.form-container .material-field.rs-5 {
  width: 5%;
  margin-left: 3%;
  float: left; }

.form-container .material-field.rs-100 .custom-input,
.form-container .material-field.rs-100-2 .custom-input,
.form-container .material-field.rs-90 .custom-input,
.form-container .material-field.rs-75 .custom-input,
.form-container .material-field.rs-70 .custom-input,
.form-container .material-field.rs-60 .custom-input,
.form-container .material-field.rs-50 .custom-input,
.form-container .material-field.rs-40 .custom-input,
.form-container .material-field.rs-40-2 .custom-input,
.form-container .material-field.rs-30 .custom-input,
.form-container .material-field.rs-25 .custom-input,
.form-container .material-field.rs-25-2 .custom-input,
.form-container .material-field.rs-15 .custom-input,
.form-container .material-field.rs-10 .custom-input,
.form-container .material-field.rs-5 .custom-input {
  flex: 0 1 100%; }

@media only screen and (max-width: 780px) {
  .form-container .material-field.rs-100,
  .form-container .material-field.rs-100-2,
  .form-container .material-field.rs-90,
  .form-container .material-field.rs-75,
  .form-container .material-field.rs-70,
  .form-container .material-field.rs-60,
  .form-container .material-field.rs-50,
  .form-container .material-field.rs-40,
  .form-container .material-field.rs-40-2,
  .form-container .material-field.rs-30,
  .form-container .material-field.rs-25,
  .form-container .material-field.rs-25-2,
  .form-container .material-field.rs-20,
  .form-container .material-field.rs-15,
  .form-container .material-field.rs-10,
  .form-container .material-field.rs-5 {
    width: 100%;
    margin-right: 0;
    float: left; } }

.form-container .material-field .MuiInputLabel-root {
  display: block;
  font-family: "Red Hat Display", Sans-serif;
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.form-container .material-field .MuiFormHelperText-root.Mui-error {
  color: #f11f10 !important;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  font-weight: 500; }

.form-container .material-field .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 8px 15px;
  font-size: 0.9rem;
  font-family: "Red Hat Display", Sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em; }

.form-container .material-field .MuiCheckbox-colorSecondary.Mui-checked {
  color: #207915; }

.form-container .material-field .MuiFormLabel-root.Mui-focused {
  color: #fff; }

.form-container .material-field .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: none; }

.form-container .material-field .MuiInput-underline:after {
  border: none; }

.form-container .material-field .MuiInput-underline:before {
  border: none; }

.form-container .material-field .MuiInputBase-input {
  font-family: "Red Hat Display", Sans-serif;
  padding: 9px 15px;
  font-size: 80%;
  color: #000000de; }

.form-container .material-field .MuiInputLabel-formControl {
  font-family: "Red Hat Display", Sans-serif;
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate(0, 7px) scale(1);
          transform: translate(0, 7px) scale(1);
  font-size: 70%; }

.form-container .material-field .MuiInputLabel-shrink {
  color: #fff;
  -webkit-transform: translate(0, 0px) scale(0);
          transform: translate(0, 0px) scale(0); }

.p-inputtextarea-resizable {
  font-family: "Red Hat Display", Sans-serif;
  overflow: hidden;
  resize: none;
  float: left;
  margin-top: 4px;
  position: initial;
  width: 100%;
  height: 90px;
  -webkit-margin-after: 30px;
          margin-block-end: 30px; }

.p-inputtext:enabled:hover {
  border-color: #d0cfcf; }

.p-inputtext:enabled:focus {
  font-family: "Red Hat Display", Sans-serif;
  border: #085e14 solid 0.8px;
  color: #767676;
  border-color: #95c21d;
  outline: 0;
  box-shadow: 0 0 0.125rem 0.25rem rgba(203, 212, 31, 0.5);
  background-color: #fff; }

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem; }

.button-wrapper .MuiButton-outlinedPrimary {
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  font-family: "Red Hat Display", Sans-serif;
  border-radius: 5px; }

.button-wrapper .MuiButton-outlinedPrimary:disabled {
  background-color: #767676; }

.button-wrapper .MuiButton-outlinedPrimary:hover {
  background-color: #cbd42f;
  border: none;
  color: #085e14;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.button-wrapper .MuiButton-outlinedPrimary[disabled="disabled"],
.button-wrapper .MuiButton-outlinedPrimary:disabled {
  background-color: #767676;
  color: #23232e; }

.button-wrapper .MuiButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.button-wrapper .MuiButton-outlinedSecondary:hover {
  font-family: "Red Hat Display", Sans-serif; }

.button-wrapper .MuiButton-outlinedSecondary[disabled="disabled"],
.button-wrapper .MuiButton-outlinedSecondary:disabled {
  background-color: #ececec;
  color: #23232e; }

.button-wrapper.delete {
  justify-content: flex-end; }

.section-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem;
  margin-bottom: 1rem; }
  .section-container .field-container .material-field .p-inputnumber {
    margin-top: 4px;
    display: inline-flex;
    width: 100%; }

.form-child {
  display: flex;
  width: auto;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem; }

.form-child h4 {
  text-align: left; }

.form-child .button-wrapper {
  justify-content: flex-end; }

.p-fileupload {
  display: flex; }

.p-button.p-fileupload-choose {
  background-color: #085e14;
  border: 1px solid #085e14; }

.no-visible {
  visibility: hidden !important; }

.p-fileupload-advanced {
  width: 100%; }

.p-fileupload-advanced .p-fileupload-content {
  width: 100%; }

.p-fileupload .p-fileupload-buttonbar {
  border: 1px solid #dee2e6; }

.btn-primary:hover {
  color: #085e14;
  background-color: #cbd42f;
  border-color: #cbd42f; }

.form-group.row label {
  white-space: nowrap; }

.MuiInputBase-root {
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 12px;
  box-sizing: border-box;
  align-items: center;
  font-family: "Red Hat Display", Sans-serif;
  font-weight: 500;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border: 1px solid #d2d2e4;
  border-radius: 2px; }

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 9px 15px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-animation-name: mui-auto-fill-cancel;
          animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent; }

.MuiInput-input:focus::-webkit-input-placeholder {
  font-size: 13px;
  background-color: white;
  color: #043a0b; }

.MuiInput-input:focus {
  border: #085e14 solid 0.8px;
  color: #767676;
  border-color: #95c21d;
  outline: 0;
  box-shadow: 0 0 0.125rem 0.25rem rgba(203, 212, 31, 0.5);
  background-color: #fff; }

.p-selectbutton .p-button {
  background-color: #dddde8;
  border: 1px solid #ced4da;
  color: #767676; }

.loading-Cities {
  background-color: #f3f3f3;
  align-items: center;
  display: flow-root;
  text-align: center;
  -webkit-transform: translateX(1%) translateY(100%);
          transform: translateX(1%) translateY(100%);
  position: static;
  padding: 2%;
  width: 70%;
  margin: 0 auto;
  font-size: 20px;
  color: #085e14;
  font-family: "Red Hat Display";
  inset: auto; }

.messageSucess {
  background-color: #f3f3f3;
  align-items: center;
  display: flow-root;
  text-align: center;
  -webkit-transform: translateX(1%) translateY(100%);
          transform: translateX(1%) translateY(100%);
  position: static;
  padding: 2%;
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  color: #23682d;
  font-family: "Red Hat Display", sans-serif;
  inset: auto; }

.messageSucess .alert.success {
  color: #085e14;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-align: center;
  padding: 10px;
  width: 100%; }

.messageSucess .alert.danger {
  color: #ac0f1e;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  padding: 10px;
  width: 100%; }

.messageSucess .buttonFinalizar {
  background-color: #085e14;
  color: #fff;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: auto;
  padding: 13px 30px;
  margin-left: 3px; }

.messageSucess .buttonFinalizar:hover {
  background-color: #95c21d;
  color: #085e14;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: auto;
  padding: 13px 30px; }

.messageError {
  padding-top: 1rem;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-size: 20px;
  text-align: center;
  color: #fff;
  font-family: "Red Hat Display"; }

.messageError .alert.success {
  color: #085e14;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-align: center;
  font-size: 20px;
  height: 100px;
  font-family: "Red Hat Display";
  padding: 20px; }

.messageError .alert.danger {
  color: #ac0f1e;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  font-size: 20px;
  height: 100px;
  font-family: "Red Hat Display";
  padding: 20px; }

.textAreaConfe {
  -webkit-margin-after: 30px;
          margin-block-end: 30px; }
  .textAreaConfe .MuiFormControl-root .p-inputtextarea-resizable {
    -webkit-margin-after: 3px;
            margin-block-end: 3px; }

.material-field .MuiFormControl-root .Mui-error {
  border-color: #dc3545; }

.messageSucessViv {
  background-color: #f3f3f3;
  align-items: center;
  display: flow-root;
  text-align: center;
  position: static;
  padding: 2%;
  width: auto;
  margin: 2%;
  font-size: inherit;
  color: #23682d;
  font-family: "Red Hat Display";
  inset: auto; }

.messageSucessViv .alert.success {
  color: #085e14;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-align: center;
  font-size: inherit;
  height: 100%;
  font-family: "Red Hat Display";
  padding: 20px;
  -webkit-margin-after: 7px;
          margin-block-end: 7px;
  width: 100%; }

.messageSucessViv .alert.danger {
  color: #ac0f1e;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  font-size: inherit;
  height: 100%;
  font-family: "Red Hat Display";
  padding: 20px;
  -webkit-margin-after: 7px;
          margin-block-end: 7px;
  width: 100%; }

.messageSucessViv .buttonFinalizar {
  background-color: #085e14;
  color: #fff;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: 20%;
  padding: 10px;
  margin-left: 3px; }

.messageSucessViv .buttonFinalizar:hover {
  background-color: #95c21d;
  color: #085e14;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: 20%;
  margin-left: 3px;
  padding: 10px; }

.messageError {
  padding-top: 1rem;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-size: 20px;
  text-align: center;
  color: #fff;
  font-family: "Red Hat Display"; }

.messageError .alert.success {
  color: #085e14;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-align: center;
  font-size: 20px;
  height: 100px;
  font-family: "Red Hat Display";
  padding: 20px; }

.messageError .alert.danger {
  color: #ac0f1e;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  font-size: 20px;
  height: 100px;
  font-family: "Red Hat Display";
  padding: 20px; }

.auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas {
  margin: auto;
  margin-right: 1%;
  background-color: #085e14;
  border: solid 1px #085e14;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif;
  width: 134%; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas .MuiButton-label {
    width: 100%;
    align-items: inherit;
    justify-content: inherit; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas .label-button {
    text-transform: initial;
    font-weight: 600;
    color: #ffffff;
    font-size: small;
    padding: 1%; }

.auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas:disabled {
  background-color: #ffffff;
  border: solid 1px #085e14;
  color: #085e14;
  font-size: 20px;
  font-weight: 600; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas:disabled .label-button {
    text-transform: initial;
    font-weight: 600;
    color: #085e14; }

.auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas:hover {
  background-color: #ffffff;
  border: solid 1px #085e14;
  color: #085e14;
  font-size: 20px;
  font-weight: 600; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-personas:hover .label-button {
    text-transform: initial;
    font-weight: 600;
    color: #085e14; }

.auth .tab-select-form .button-wrapper .MuiButtonGroup-root {
  width: 60%; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas {
    margin: auto;
    margin-right: 1%;
    background-color: #085e14;
    border: solid 1px #085e14;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 9px 19px;
    border-radius: 5px;
    font-family: "Red Hat Display", Sans-serif;
    width: 134%; }
    .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas .MuiButton-label {
      width: 100%;
      align-items: inherit;
      justify-content: inherit; }
    .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas .label-button {
      text-transform: initial;
      font-weight: 600;
      color: #ffffff;
      font-size: small;
      padding: 1%; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas:disabled {
    background-color: #ffffff;
    border: solid 1px #085e14;
    color: #085e14;
    font-size: 20px;
    font-weight: 600; }
    .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas:disabled .label-button {
      text-transform: initial;
      font-weight: 600;
      color: #085e14; }
  .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas:hover {
    background-color: #ffffff;
    border: solid 1px #085e14;
    color: #085e14;
    font-size: 20px;
    font-weight: 600; }
    .auth .tab-select-form .button-wrapper .MuiButtonGroup-root .auth-btn-togle-empresas:hover .label-button {
      text-transform: initial;
      font-weight: 600;
      color: #085e14; }

.form-container .captcha-valid .recaptcha div div div {
  display: table;
  width: auto;
  margin: auto; }

.field-container .material-field .p-autocomplete {
  width: 100%;
  margin-top: 1%; }

.field-container .modal-autocomplete .p-autocomplete .p-autocomplete-panel {
  max-height: 200px;
  z-index: 1000;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  top: 39px !important;
  left: 0px !important; }

.form-tooltip {
  float: right;
  margin-right: 0.5rem;
  background-color: #fff;
  color: #000; }

.loading-wrapper {
  padding-top: 3rem;
  width: 280px;
  margin: 0 auto;
  margin-bottom: 2rem; }
  .loading-wrapper .MuiLinearProgress-root {
    margin-bottom: 0.5rem; }

.button-logout {
  padding: 12% 0% 0% 74%;
  position: absolute;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem; }
  .button-logout .MuiButtonGroup-root {
    display: inherit; }

.button-logout .MuiButton-outlinedPrimary:hover {
  background-color: #cbd42f;
  border: none;
  color: #085e14;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.button-logout .MuiButton-outlinedPrimary {
  background-color: #085e14;
  color: #fff;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.button-nav-bandeja {
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem; }
  .button-nav-bandeja .MuiButtonGroup-root {
    display: inherit; }

.button-nav-bandeja .MuiButton-outlinedPrimary:hover {
  background-color: #cbd42f;
  border: none;
  color: #085e14;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.button-nav-bandeja .MuiButton-outlinedPrimary {
  background-color: #085e14;
  color: #fff;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.modalEsperaCharged {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 8%; }
  .modalEsperaCharged .css-18lrjg1-MuiCircularProgress-root {
    color: #19d22b; }

.auth h1 {
  font-size: 2rem;
  text-align: center; }

.auth .auth-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
  background-color: white; }

.auth .auth-list_wrapper {
  width: 90%;
  margin: 0 auto; }
  .auth .auth-list_wrapper .card-header {
    background-color: #23232e;
    color: #ececec;
    padding: 0.6rem;
    line-height: 1;
    border-radius: 4px 4px 0 0; }
  .auth .auth-list_wrapper .card-body {
    background-color: white;
    padding: 1rem; }
  .auth .auth-list_wrapper .auth-btn-sign,
  .auth .auth-list_wrapper .auth-btn-login {
    margin-top: 1rem; }
  .auth .auth-list_wrapper header.tab-wrapper-authentication-tabs {
    background: transparent;
    box-shadow: none; }
    .auth .auth-list_wrapper header.tab-wrapper-authentication-tabs button {
      color: #fff;
      font-weight: bold;
      border: 1px solid #000;
      border-radius: 4px 4px 0 0;
      background: #23232e; }

.auth .form-custom-group {
  flex-direction: column; }
  .auth .form-custom-group .label-name {
    display: block;
    text-align: left; }

.auth .form-title {
  text-align: center;
  border-bottom: 1px solid #ccc; }

.auth .form-container .p-button.p-fileupload-choose {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #085e14;
  border-radius: 3px;
  color: #085e14;
  display: flex; }

.auth .form-container .p-button.p-fileupload-choose:hover {
  padding: 5px;
  background-color: #95c21d;
  border: 1px solid #95c21d;
  border-radius: 3px;
  color: #085e14;
  display: flex; }

.auth .form-container .adjuntar {
  min-height: 174px; }
  .auth .form-container .adjuntar .label-name {
    float: left;
    background: #ea5c09;
    text-align: left;
    color: white;
    padding: 6px 8px;
    font-family: "Red Hat Display";
    border-radius: 5px;
    margin: 5px 0px;
    width: 90%;
    font-size: 13px;
    min-height: 100px; }
  .auth .form-container .adjuntar .p-fileupload-advanced {
    width: 90%;
    margin-left: 4%; }
    .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-buttonbar {
      width: 40%;
      padding: 0%; }
      .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-buttonbar .p-button .p-button-icon-left {
        margin-right: 0rem; }
      .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
        margin: 0px;
        padding: 3%;
        font-size: 12; }
        .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose .p-button-label {
          font-size: 14px; }
      .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover {
        padding: 3%;
        font-size: 12; }
    .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-content {
      padding: 0%;
      width: 100%; }
      .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row {
        font-size: 11px; }
        .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row div {
          padding: 7px 0px 0px 0px;
          margin: 0px 5px;
          width: auto; }
        .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row .p-button.p-button-icon-only {
          width: 1.357rem;
          padding: 0 0; }
      .auth .form-container .adjuntar .p-fileupload-advanced .p-fileupload-content .p-fileupload-row > div > img {
        width: auto;
        max-width: 60px; }

.auth .form-container .adjuntarDinamic {
  min-height: 174px; }
  .auth .form-container .adjuntarDinamic .label-name {
    float: left;
    background: #085e14;
    text-align: left;
    color: white;
    padding: 6px 8px;
    font-family: "Red Hat Display";
    border-radius: 5px;
    margin: 5px 0px;
    width: 90%;
    font-size: 13px; }
  .auth .form-container .adjuntarDinamic .p-fileupload-advanced {
    width: 90%;
    margin-left: 4%; }
    .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-buttonbar {
      width: 40%;
      padding: 0%; }
      .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-buttonbar .p-button .p-button-icon-left {
        margin-right: 0rem; }
      .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
        margin: 0px;
        padding: 3%;
        font-size: 12; }
        .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose .p-button-label {
          font-size: 14px; }
      .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover {
        padding: 3%;
        font-size: 12; }
    .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-content {
      padding: 0%;
      width: 100%; }
      .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row {
        font-size: 11px; }
        .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row div {
          padding: 7px 0px 0px 0px;
          margin: 0px 5px;
          width: auto; }
        .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row .p-button.p-button-icon-only {
          width: 1.357rem;
          padding: 0 0; }
      .auth .form-container .adjuntarDinamic .p-fileupload-advanced .p-fileupload-content .p-fileupload-row > div > img {
        width: auto;
        max-width: 60px; }

.auth .form-container .adjuntar2 {
  min-height: 174px; }
  .auth .form-container .adjuntar2 .p-fileupload-advanced {
    width: 90%;
    margin-left: 4%; }
    .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-buttonbar {
      width: 40%;
      padding: 0%; }
      .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-buttonbar .p-button .p-button-icon-left {
        margin-right: 0rem; }
      .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
        margin: 0px;
        padding: 3%;
        font-size: 12; }
        .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose .p-button-label {
          font-size: 14px; }
      .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover {
        padding: 3%;
        font-size: 12; }
    .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-content {
      padding: 0%;
      width: 100%; }
      .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row {
        font-size: 11px; }
        .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row div {
          padding: 7px 0px 0px 0px;
          margin: 0px 5px;
          width: auto; }
        .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row .p-button.p-button-icon-only {
          width: 1.357rem;
          padding: 0 0; }
      .auth .form-container .adjuntar2 .p-fileupload-advanced .p-fileupload-content .p-fileupload-files .p-fileupload-row > div > img {
        width: auto;
        max-width: 60px; }
  .auth .form-container .adjuntar2 .label-name {
    float: left;
    background: #085e14;
    text-align: left;
    color: white;
    padding: 6px 8px;
    font-family: "Red Hat Display";
    border-radius: 5px;
    margin: 5px 0px;
    width: 90%;
    font-size: 13px;
    min-height: 100px; }

.auth .form-container .form-text {
  padding-left: 1rem; }

.auth .form-container h2,
.auth .form-container p {
  text-align: left; }

.auth .form-container h2 {
  padding-bottom: 1rem; }

.auth .form-container .button-wrapper .auth-btn-cancel {
  color: #085e14;
  background-color: #fff;
  border: solid 1px #085e14;
  font-weight: 500;
  font-size: 15px;
  width: 123px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif;
  margin-right: 2%; }

.auth .form-container .button-wrapper .auth-btn-cancel:hover {
  background-color: #085e14;
  border: 1px solid #085e14;
  margin-right: 2%;
  color: #fff;
  font-weight: 500;
  border-radius: 5px; }

.auth .form-container .button-wrapper .MuiButton-outlinedPrimary {
  color: white;
  background-color: #085E14;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  font-family: "Red Hat Display", Sans-serif;
  border-radius: 5px; }
  .auth .form-container .button-wrapper .MuiButton-outlinedPrimary:disabled {
    background-color: #767676; }
  .auth .form-container .button-wrapper .MuiButton-outlinedPrimary:hover {
    background-color: #cbd42f;
    border: none;
    color: #085E14;
    font-size: 16px;
    font-weight: 500;
    padding: 9px 19px;
    border-radius: 5px;
    font-family: "Red Hat Display", Sans-serif; }

.auth .form-container .button-wrapper .delete {
  color: #3d3f3d;
  background-color: #d6d4d4;
  border: solid 1px #000000;
  font-weight: 500;
  font-size: 17px;
  width: 10%;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif;
  margin-right: 2%;
  background-image: href("delete.png"); }

.auth .form-container .button-wrapper .delete:hover {
  color: #3d3f3d;
  background-color: #684c4c;
  border: solid 1px #000000;
  font-weight: 500;
  font-size: 17px;
  width: 10%;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif;
  margin-right: 2%;
  background-image: href("delete.png"); }

.auth .form-container .material-field .MuiFormControl-root.datepicker {
  margin-top: 0px; }

.auth .juramentoPartes {
  align-items: center; }
  .auth .juramentoPartes .spacerJuramento {
    align-items: center; }
    .auth .juramentoPartes .spacerJuramento .label-name {
      font-family: "Red hat Display";
      text-align: justify;
      padding: 5% 12%;
      font-weight: 500; }
  .auth .juramentoPartes .AceptarJuramento {
    margin-left: 12%;
    padding: 0px; }
    .auth .juramentoPartes .AceptarJuramento .label-name {
      display: none; }
    .auth .juramentoPartes .AceptarJuramento div .MuiFormControlLabel-root .MuiTypography-body1 {
      color: green;
      float: left;
      font-size: 17px;
      font-family: "Red Hat Display", sans-serif;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.00938em; }
    .auth .juramentoPartes .AceptarJuramento .MuiFormControlLabel-root {
      display: flex; }
      .auth .juramentoPartes .AceptarJuramento .MuiFormControlLabel-root .PrivateSwitchBase-root-8 {
        padding: 2px 9px;
        float: left; }
    .auth .juramentoPartes .AceptarJuramento .PrivateSwitchBase-root-1 {
      float: left;
      padding: 1px 9px; }

.auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files {
  box-sizing: border-box;
  height: inherit; }
  .auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files .p-fileupload-row {
    display: -ms-flexbox;
    display: -webkit-box;
    align-items: center;
    height: 67px;
    font-size: 75%;
    margin-top: 3px; }
  .auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files .p-fileupload-row > div {
    padding: 3px;
    text-align: justify; }
    .auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files .p-fileupload-row > div .p-button.p-button-icon-only {
      width: 46%;
      padding: 0.5rem 0;
      margin: 5%;
      float: right;
      margin-top: 10%;
      background: #fff;
      border: green solid 1.5px;
      color: #1aad4e;
      font-weight: bold; }
    .auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files .p-fileupload-row > div .p-button.p-button-icon-only:hover {
      width: 46%;
      padding: 0.5rem 0;
      margin: 5%;
      float: right;
      margin-top: 10%;
      background: #93bb36;
      border: green solid 1.5px;
      color: #196e37;
      font-weight: bold; }
  .auth .adjuntar .adjuntar2 .MuiFormControl-root .p-fileupload-content .p-fileupload-files .p-fileupload-row > div > img {
    width: auto;
    max-width: 60px; }

.auth .custom-select {
  margin-top: 1rem;
  text-align: left; }

.auth .file-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .auth .file-container .custom-file {
    margin-left: -95px; }
  .auth .file-container .button-file {
    min-width: 120px; }

.auth h2 {
  color: #085E14; }

.auth .MuiSwitch-thumb {
  color: #085E14; }

.auth .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb {
  color: #95c21d; }

.auth .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  color: #555558;
  background-color: #555558; }

.auth .card,
.auth .card-body {
  background-color: #fff; }

.auth .p-selectbutton .p-button.p-highlight {
  background-color: #085E14;
  border-color: #95c21d;
  font-size: 1.5rem; }

.auth .stepper-form {
  width: 90%;
  margin: 0 auto; }

.auth .MuiStepIcon-root {
  cursor: pointer; }
  .auth .MuiStepIcon-root.MuiStepIcon-completed {
    color: #085E14; }
    .auth .MuiStepIcon-root.MuiStepIcon-completed:hover {
      color: #95c21d; }
  .auth .MuiStepIcon-root.MuiStepIcon-active {
    color: #085E14; }
    .auth .MuiStepIcon-root.MuiStepIcon-active:hover {
      color: #95c21d; }

.auth h3 {
  color: #085E14;
  text-align: center; }

.auth .integrantes .button-wrapper,
.auth .previos .button-wrapper {
  justify-content: flex-end; }

.auth .integrantes .auth-btn-cancel,
.auth .previos .auth-btn-cancel {
  background-color: #b6b6b6;
  color: #d82c19;
  font-weight: bold; }

.auth .auth-btn-send {
  background-color: #085E14; }

.auth .MuiAlert-root {
  width: 50%;
  margin: 0 auto;
  justify-content: center; }

body::-webkit-scrollbar {
  width: 20px; }

body::-webkit-scrollbar-thumb {
  background: #808380;
  border-right: 3px solid #e9ecef;
  border-radius: 5px; }

body::-webkit-scrollbar-track {
  background: #e9ecef; }

.auth .auth-container {
  width: 95%; }

.firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-content {
  background: #ffffff;
  padding: 0px;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 10px; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-content .p-fileupload-row > div {
    padding: 2px 5px; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-content .p-fileupload-row > div {
    flex: auto 1 1; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-content .p-fileupload-row > div > img {
    width: auto;
    max-width: 60px; }

.firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar {
  border: 1px solid #dee2e6;
  padding: 2px; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar .p-button .p-button-icon-left {
    margin-right: 0rem; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
    background-color: #fff;
    border: 1px solid #085e14;
    border-radius: 3px;
    color: #085e14;
    display: grid;
    font-size: 11px;
    font-family: "Red Hat Display", Sans-serif;
    margin: 1%;
    width: 100%; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover {
    background-color: #cbd42f;
    border: none;
    color: #085e14;
    font-size: 11px;
    font-weight: 500;
    border-radius: 5px;
    font-family: "Red Hat Display", Sans-serif; }

.firmaClass .p-fileupload-buttonbar {
  border: 1px solid #dee2e6;
  padding: 2px; }
  .firmaClass .p-fileupload-buttonbar .p-button.p-fileupload-choose {
    background-color: #fff;
    border: 1px solid #085e14;
    border-radius: 3px;
    color: #085e14;
    display: grid;
    font-size: 11px;
    font-family: "Red Hat Display", Sans-serif;
    margin: 1%;
    width: 100%; }

.firmaClass .p-fileupload-advanced .p-fileupload-content {
  width: 100%;
  height: 55%; }

.firmaClass .p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 8%;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 11px; }

.auth .field-container .instruccion2 .button-Update {
  width: 80%;
  background-color: #ffffff;
  padding: 4px 0px;
  border: 1px solid #008a17;
  border-radius: 10px;
  display: inline-block;
  -webkit-margin-after: 2%;
          margin-block-end: 2%; }
  .auth .field-container .instruccion2 .button-Update .parrafo-Update {
    font-size: medium;
    font-family: "Red Hat Display";
    color: #008a17;
    text-align: center; }
  .auth .field-container .instruccion2 .button-Update .instruccion2 {
    width: auto;
    background-color: #006711;
    padding: 6px 7px;
    border: 1px solid #008a17;
    border-radius: 5px;
    font-size: large;
    font-family: "Red Hat Display";
    font-weight: 500;
    color: #ffffff;
    display: inline-block;
    text-transform: inherit;
    text-align: center; }

.instruccion {
  align-items: center;
  padding: 1%; }
  .instruccion .labelIns .labelInstruccion {
    width: 80%;
    background-color: #68a62e;
    padding: 14px;
    border: 1px solid #008a17;
    border-radius: 12px;
    font-size: 14px;
    font-family: "Red Hat Display";
    font-weight: 500;
    color: #fff;
    display: inline-block; }

.auth .field-container .instruccion {
  align-items: center;
  padding: 0%;
  min-height: 4.6rem; }

.form-container .material-field li .labelIns .labelInstruccion {
  width: 80%;
  background-color: #68a62e;
  padding: 14px;
  border: 1px solid #008a17;
  border-radius: 12px;
  font-size: 14px;
  font-family: "Red Hat Display";
  font-weight: 500;
  color: #fff;
  display: inline-block; }

.modalValidador {
  background-color: transparent; }
  .modalValidador .validador_div {
    background-color: #fff;
    border-radius: 4px;
    border: solid #085e14;
    color: #085e14;
    margin: 15% auto;
    padding: 3%;
    font-size: x-large;
    font-family: "Red Hat Display";
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .modalValidador .validador_div .MuiCircularProgress-colorPrimary {
      color: #95c21d;
      margin: 0px 14px; }

.material-field .button-AddRecurso .MuiButton-textPrimary {
  width: 86%;
  margin: 11%;
  padding: 3% 4%;
  font-size: 100%;
  border-radius: 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  font-weight: 500;
  background: #085e14; }

.material-field .button-AddRecurso .MuiButton-textPrimary:hover {
  color: #085e14;
  padding: 3% 4%;
  background: #cbd42f; }

.material-field .button-AddRecurso .MuiButton-textPrimary:disabled {
  width: 83%;
  margin: 11%;
  font-size: 16px;
  border-radius: 13px;
  border: 1px #fff solid;
  color: #fff;
  padding: 10px 14px;
  font-weight: 500;
  background: #767676; }

.material-field .button-cleanRecurso .MuiButton-textPrimary {
  margin-top: 24%;
  padding: 3% 4%;
  font-size: larger;
  border-radius: 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  font-weight: bold;
  background: #dd9001;
  float: left; }
  .material-field .button-cleanRecurso .MuiButton-textPrimary .MuiSvgIcon-root {
    width: 2rem;
    height: 2rem; }

.material-field .button-cleanRecurso .MuiButton-textPrimary:hover {
  border-radius: 10px;
  border: 1px solid #dd9001;
  color: #dd9001;
  background: #fff; }

.material-field .button-cleanRecurso .MuiButton-textPrimary:disabled {
  width: 83%;
  margin: 11%;
  font-size: 16px;
  border-radius: 13px;
  border: 1px #fff solid;
  color: #fff;
  padding: 10px 14px;
  font-weight: 500;
  background: #767676; }

.material-field .button-AddRecurso:disabled .MuiButtonBase-root:disabled .MuiButton-label {
  width: 100%;
  font-size: 16px;
  border-radius: 13px;
  border: 1px #085e14 solid;
  color: white;
  padding: 10px 14px;
  font-weight: 500;
  background: #767676; }

.material-field .tableEmpresa .MuiTable-root .MuiTableBody-root {
  background-color: #dddfdb; }
  .material-field .tableEmpresa .MuiTable-root .MuiTableBody-root .MuiTableCell-body {
    padding: 12px 5px;
    font-size: 14px;
    border-bottom: 0px solid #fff; }

.material-field .tableAhorro .MuiTable-root .MuiTableBody-root {
  background-color: #dddfdb; }
  .material-field .tableAhorro .MuiTable-root .MuiTableBody-root .MuiTableCell-body {
    padding: 12px 5px;
    font-size: 14px;
    border-bottom: 0px solid #fff; }

.material-field .tableInth .MuiTable-root .MuiTableBody-root {
  background-color: #dddfdb; }
  .material-field .tableInth .MuiTable-root .MuiTableBody-root .MuiButton-label {
    margin: 0px;
    width: 70%;
    font-size: 10px;
    border-radius: 10px;
    border: 1px #085e14;
    color: white;
    font-weight: 400;
    background: #085e14; }
  .material-field .tableInth .MuiTable-root .MuiTableBody-root .MuiButton-label:hover {
    margin: 0px;
    width: 70%;
    font-size: 10px;
    border-radius: 10px;
    border: 1px #95c21d;
    color: #085e14;
    font-weight: 500;
    background: #95c21d; }

.material-field .MuiTable-root .MuiTableHead-root {
  display: table-header-group;
  background: #085e14;
  color: #fff;
  font-weight: 500;
  font-family: sans-serif; }
  .material-field .MuiTable-root .MuiTableHead-root .MuiTableCell-head {
    border-bottom: 0px solid #fff;
    color: #fff;
    padding: 16px;
    font-weight: 500;
    line-height: 0.8rem;
    font-size: 17px;
    font-family: "Red Hat Display", sans-serif;
    text-align: center; }
  .material-field .MuiTable-root .MuiTableHead-root .MuiButton-label {
    width: 100%;
    font-size: 16px;
    border-radius: 13px;
    border: 1px #085e14 solid;
    color: white;
    font-weight: 500;
    background: #085e14; }
  .material-field .MuiTable-root .MuiTableHead-root .MuiButton-label:hover {
    width: 100%;
    font-size: 16px;
    border-radius: 13px;
    border: 1px #085e14 solid;
    color: #085e14;
    font-weight: 500;
    background: #41c40d; }

.material-field .MuiTable-root .MuiTableBody-root {
  background-color: #dddfdb; }
  .material-field .MuiTable-root .MuiTableBody-root .MuiTableCell-body {
    text-align: center;
    border-bottom: 0px solid #fff;
    color: #085e14;
    font-weight: 500;
    line-height: 0.8rem;
    font-size: 12px;
    font-family: "Red Hat Display", sans-serif;
    padding: 0px 1px; }
  .material-field .MuiTable-root .MuiTableBody-root .MuiButton-label {
    width: 100%;
    font-size: 13px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    background: #dc004e;
    padding: 2px 6px; }
  .material-field .MuiTable-root .MuiTableBody-root .MuiButton-label:hover {
    width: 100%;
    font-size: 13px;
    border-radius: 4px;
    color: #dc004e;
    font-weight: 500;
    background: #fff;
    padding: 2px 6px; }

.material-field .MuiFormControl-root .tableUp {
  border: none;
  box-shadow: none; }
  .material-field .MuiFormControl-root .tableUp .MuiTable-root {
    width: 90%;
    margin-left: 4%;
    border-radius: 3px;
    margin-top: 0.5%; }
    .material-field .MuiFormControl-root .tableUp .MuiTable-root .MuiTableHead-root .MuiTableCell-head {
      color: #fff;
      padding: 13px;
      font-weight: 500;
      line-height: 0;
      font-size: 13px;
      font-family: "Red Hat Display", sans-serif;
      text-align: center; }

.material-field .p-autocomplete-dd .p-autocomplete-input,
.material-field .p-autocomplete-dd .p-autocomplete-multiple-container {
  opacity: 1.2; }

.pdf-component .pdf-doc {
  width: 100vw;
  height: 100vh; }

.auth .field-container .material-field div .MuiFormHelperText-root {
  color: red;
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
  font-family: "Red Hat Display", sans-serif;
  padding: 0px; }

.form-tooltip {
  color: #fdc100d9;
  float: left;
  margin-right: 1%; }

.form-container .label-name {
  float: left;
  color: #085e14;
  font-weight: 500;
  font-size: 90%; }

.firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar .p-button {
  padding: 3px 16px; }
  .firmaClass .MuiFormControl-root .p-fileupload-advanced .p-fileupload-buttonbar .p-button .p-button-icon-left {
    margin-right: 0rem; }

.firmaClass .MuiFormControl-root .table-upload-firma .tableFirma .MuiTable-root .MuiTableHead-root .MuiTableCell-head {
  padding: 6px 10px;
  font-size: 14px; }

.form-wrapper .table .form-child .field-container .material-field .MuiTableHead-root {
  display: table-header-group;
  background: #085e14; }

.form-wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
  padding: 6px 20px; }

.material-field .MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root .MuiRadio-colorSecondary.Mui-checked {
  color: #2ac520; }

.auth .form-container .button-wrapper .MuiButton-outlinedPrimary {
  font-size: 15px; }

.table-upload-firma .tableFirma .MuiTable-root .MuiTableBody-root .MuiButton-text {
  padding: 1px 13px; }
  .table-upload-firma .tableFirma .MuiTable-root .MuiTableBody-root .MuiButton-text .MuiButton-label {
    width: 100%;
    font-size: 11px;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    background: #68a62e;
    padding: 0px 2px; }
    .table-upload-firma .tableFirma .MuiTable-root .MuiTableBody-root .MuiButton-text .MuiButton-label .MuiButton-startIcon {
      display: inherit;
      margin-left: 0px;
      margin-right: 0px; }

.auth .alertsucessBorrador {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  border: solid #edf7ed;
  font-size: 20px;
  color: green;
  font-family: "Red Hat Display", sans-serif; }

.ocultar {
  visibility: collapse;
  left: 0%; }

.material-field .section-container .field-container .instruccion2 .button-Update .instruccion2 {
  width: auto; }

.auth-wrapper .messageSucess {
  -webkit-transform: translateX(1%) translateY(10%);
          transform: translateX(1%) translateY(10%); }

.p-dialog {
  width: auto;
  min-width: 348px;
  max-height: 307px; }

.material-field .MuiTable-root .MuiTableBody-root .MuiButton-label {
  color: #fff;
  background: #68a62e; }

.material-field .MuiTable-root .MuiTableBody-root .MuiButton-label:hover {
  color: #054d0e;
  background: #6e8a08; }

.auth .field-container .instruccion .card-content-anexo {
  background-color: #68a62e;
  padding: 1%;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center; }
  .auth .field-container .instruccion .card-content-anexo h5 {
    font-size: inherit;
    font-weight: 500;
    font-family: "Red Hat Display", sans-serif;
    color: #fff; }
  .auth .field-container .instruccion .card-content-anexo .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
    font-family: "Red Hat Display", sans-serif;
    color: #085e14; }
  .auth .field-container .instruccion .card-content-anexo .p-panel .p-panel-content {
    display: inline-block;
    border: solid 1px #085e14; }
    .auth .field-container .instruccion .card-content-anexo .p-panel .p-panel-content p {
      font-size: inherit;
      font-weight: 500;
      font-family: "Red Hat Display", sans-serif;
      color: #085e14;
      text-align: justify; }

.auth .juramentoPartes .spacerJuramento .label-name {
  padding: 3% 12%;
  font-weight: 500;
  width: 80%; }

.auth .juramentoPartes .spacerJuramento .card-content-jura {
  padding: 1% 5% 5% 5%;
  margin: 0 auto;
  border-radius: 5px; }
  .auth .juramentoPartes .spacerJuramento .card-content-jura h5 {
    font-size: inherit;
    font-weight: 500;
    font-family: "Red Hat Display", sans-serif;
    color: #fff; }
  .auth .juramentoPartes .spacerJuramento .card-content-jura .p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
    font-family: "Red Hat Display", sans-serif;
    color: #085e14; }
  .auth .juramentoPartes .spacerJuramento .card-content-jura .p-panel .p-panel-content {
    display: inline-block;
    border: solid 2px #d1d1d1; }
    .auth .juramentoPartes .spacerJuramento .card-content-jura .p-panel .p-panel-content p {
      font-size: inherit;
      font-weight: 500;
      font-family: "Red Hat Display", sans-serif;
      color: #085e14;
      text-align: justify; }

.auth .field-container .material-field .cuentasIns {
  -webkit-margin-after: 1%;
          margin-block-end: 1%; }
  .auth .field-container .material-field .cuentasIns .labelInstruccion p {
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-size: 14px;
    font-family: "Red Hat Display", sans-serif;
    text-align: justify; }

.video-container {
  background: #ffffff00;
  margin: 0px;
  padding: 4px;
  position: fixed;
  float: right;
  right: 3%;
  border-radius: 10px;
  /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden; }
  .video-container .button-closed {
    float: right;
    -webkit-margin-after: 2px;
            margin-block-end: 2px; }
    .video-container .button-closed .MuiButton-containedSizeLarge {
      background: brown;
      padding: 0px; }

.auth-wrapper .button-openFrame {
  float: right; }
  .auth-wrapper .button-openFrame .MuiButtonBase-root {
    border-radius: 10px 0px 0px 10px;
    border: 1px solid green;
    background-color: white;
    color: green;
    padding: 10px; }

.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.auth-container .section-container .field-container .material-field .divArchivo {
  min-width: 100%;
  margin: auto;
  display: flex; }
  .auth-container .section-container .field-container .material-field .divArchivo .labelArchivo {
    width: 70%;
    margin-left: 1%;
    margin-right: 0.3%;
    float: left;
    color: #1b7c09;
    padding: 8px 15px;
    font-size: 0.9rem;
    font-family: "Red Hat Display", Sans-serif;
    font-weight: 600;
    background: #fff;
    border: solid 1px;
    border-radius: 4px;
    overflow: hidden; }
    .auth-container .section-container .field-container .material-field .divArchivo .labelArchivo .MuiSvgIcon-root {
      margin-right: 5px;
      padding: 0px; }
  .auth-container .section-container .field-container .material-field .divArchivo .MuiButtonGroup-root {
    width: 30%;
    margin-left: 0.3%;
    margin-right: 1%;
    float: right; }
    .auth-container .section-container .field-container .material-field .divArchivo .MuiButtonGroup-root .buttonFile-see {
      color: #fff;
      font-family: "Red Hat Display", Sans-serif;
      background: #1b7c09;
      border: solid 1px #1b7c09;
      border-radius: 4px; }
    .auth-container .section-container .field-container .material-field .divArchivo .MuiButtonGroup-root .buttonFile-see:hover {
      color: #1b7c09;
      font-family: "Red Hat Display", Sans-serif;
      background: #fff;
      border: solid 1px #1b7c09;
      border-radius: 4px; }
    .auth-container .section-container .field-container .material-field .divArchivo .MuiButtonGroup-root .buttonFile-delete {
      margin-left: 0.6%;
      color: #fff;
      font-family: "Red Hat Display", Sans-serif;
      background: #e0932d;
      border: solid 1px #e0932d;
      border-radius: 4px; }
    .auth-container .section-container .field-container .material-field .divArchivo .MuiButtonGroup-root .buttonFile-delete:hover {
      margin-left: 0.6%;
      color: #e0932d;
      font-family: "Red Hat Display", Sans-serif;
      background: #fff;
      border: solid 1px #e0932d;
      border-radius: 4px; }

.modal-Instruccion .MuiDialog-paperWidthSm {
  max-width: 80%; }
  .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogTitle-root {
    margin: auto; }
    .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogTitle-root h2 {
      color: #085e14;
      font-family: "Red Hat Display", sans-serif;
      font-weight: bold; }
  .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .form-wrapper .material-field .spacerJuramento .p-panel-content {
    color: green;
    float: left;
    font-size: 17px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.00938em; }
  .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess {
    font-size: large; }
    .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess .MuiButtonGroup-root {
      margin: auto; }
      .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess .MuiButtonGroup-root .MuiButton-root {
        padding: 10px;
        font-weight: bold;
        font-size: medium;
        font-family: "Red Hat Display", sans-serif;
        border-radius: 10px;
        display: flex;
        margin-left: 1%; }
      .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess .MuiButtonGroup-root .btn-cancelar {
        color: #085e14;
        background-color: #cdd432;
        border: 1px solid #cdd432; }
      .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess .MuiButtonGroup-root .btn-aceptar {
        color: #fff;
        background-color: #085e14;
        border: 1px solid #085e14; }
      .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogContent-root .messageSucess .MuiButtonGroup-root .MuiButton-root:hover {
        padding: 10px;
        font-weight: bold;
        font-size: medium;
        font-family: "Red Hat Display", sans-serif;
        background-color: #fff;
        border: 1px solid #085e14;
        border-radius: 10px;
        color: #085e14;
        display: flex;
        margin-left: 1%; }
  .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogActions-spacing {
    margin: auto; }
    .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogActions-spacing .MuiButtonGroup-root .MuiButton-root {
      padding: 10px;
      font-weight: bold;
      font-size: larger;
      font-family: "Red Hat Display", sans-serif;
      background-color: #085e14;
      border: 1px solid #085e14;
      border-radius: 10px;
      color: #fff;
      display: flex; }
    .modal-Instruccion .MuiDialog-paperWidthSm .MuiDialogActions-spacing .MuiButtonGroup-root .MuiButton-root:hover {
      padding: 10px;
      font-weight: bold;
      font-size: larger;
      font-family: "Red Hat Display", sans-serif;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      color: #085e14;
      display: flex; }

.submit-final-form .MuiDialog-container .MuiDialog-paperWidthSm {
  min-width: 30%; }
  .submit-final-form .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogTitle-root h2 {
    color: #085e14;
    text-align: center;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    font-size: x-large; }
  .submit-final-form .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root {
    margin: auto; }
    .submit-final-form .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root {
      padding: 10px;
      font-weight: bold;
      font-size: large;
      font-family: "Red Hat Display", sans-serif;
      background-color: #085e14;
      border: 1px solid #085e14;
      border-radius: 10px;
      color: #fff;
      display: flex;
      margin-left: 2%; }
    .submit-final-form .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root:hover {
      background-color: #fff;
      border: 1px solid #085e14;
      border-radius: 10px;
      color: #085e14; }

.conversation h1 {
  font-size: 2rem;
  text-align: center; }

.conversation .conversation-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem; }

.conversation .user-list_wrapper {
  width: 60%;
  margin: 0 auto; }

.conversation .conversation-list_wrapper {
  width: 60%;
  margin: 0 auto; }

.admin {
  margin: 3rem; }
  .admin .cards-wrapper {
    width: 300px;
    margin: 0 auto; }
  .admin .card {
    border-radius: 5px 5px 0 0; }
    .admin .card .card-header {
      background-color: #085E14;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      border-radius: 5px 5px 0 0;
      padding: 0.5rem; }
      .admin .card .card-header p {
        display: none; }
  .admin .card-body {
    padding: 1rem; }
  .admin h2 {
    margin: 0; }
  .admin .content_tab {
    padding: 1rem;
    text-align: center; }
  .admin .recharts-wrapper {
    margin: 0 auto; }
  .admin .tabs_container .MuiTabs-flexContainer {
    display: flex;
    justify-content: center; }

.card .claseTablas {
  background: red; }
  .card .claseTablas .p-datatable .p-datatable-tbody > tr > td,
  .card .claseTablas .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1px 3px;
    width: inherit; }

.datatable-crud-demo .card .claseTablas .p-datatable-header .table-header h5 {
  font-size: 177%;
  margin: 10px 0px;
  color: green; }

.datatable-crud-demo .card .claseTablas .p-datatable-scrollable-wrapper .p-datatable-scrollable-view .p-datatable-scrollable-header .p-datatable-scrollable-header-box .p-datatable-scrollable-header-table {
  width: inherit; }
  .datatable-crud-demo .card .claseTablas .p-datatable-scrollable-wrapper .p-datatable-scrollable-view .p-datatable-scrollable-header .p-datatable-scrollable-header-box .p-datatable-scrollable-header-table .p-datatable-thead tr th {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1px 3px;
    min-width: 200px; }

.datatable-crud-demo .card .claseTablas .p-datatable-scrollable-wrapper .p-datatable-scrollable-view .p-datatable-scrollable-body .p-datatable-scrollable-body-table {
  width: inherit; }
  .datatable-crud-demo .card .claseTablas .p-datatable-scrollable-wrapper .p-datatable-scrollable-view .p-datatable-scrollable-body .p-datatable-scrollable-body-table .p-datatable-tbody tr td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1px 3px;
    min-width: 200px;
    font-size: 80%; }

.Component-root-7 .Component-root-8 {
  fill: #000000;
  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: normal; }

.Component-root-5 div .MuiTypography-h5 {
  margin-top: 5%;
  font-size: 2rem;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  line-height: 1.334;
  letter-spacing: 0em; }

.p-jc-center h2 {
  margin-top: 5%;
  color: green;
  font-size: 2rem;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  line-height: 1.334;
  letter-spacing: 0em; }

.p-jc-center .p-chart {
  margin-top: 2%;
  width: 100%; }

.card h5 {
  -webkit-margin-after: 2%;
          margin-block-end: 2%;
  margin-top: 5%;
  color: green;
  font-size: 2rem;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  line-height: 1.334;
  letter-spacing: 0em; }

.contacto-wrapper {
  background-color: #f1f1f11a;
  margin: 1% 2%;
  padding: 2%; }

.section-Contacto {
  border: solid 1px #b9b7b7;
  margin: 1% 0%;
  padding: 2%; }

.field-container .material-field div .MuiFormHelperText-root {
  color: #ff0000;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em; }

.messageSucess {
  -webkit-transform: translateX(1%) translateY(60%);
          transform: translateX(1%) translateY(60%); }
  .messageSucess .alert.danger {
    display: inherit; }
    .messageSucess .alert.danger .MuiAlert-message {
      font-size: 20px;
      font-weight: 500;
      font-family: "Red Hat Display", sans-serif; }

.principal-wrapper {
  background: #fff;
  border: 1px solid gray; }
  .principal-wrapper .header-wrapper .title {
    color: green;
    font-family: "Red Hat Display", sans-serif;
    font-size: x-large;
    margin: auto;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
    padding-top: 2%; }
  .principal-wrapper .header-wrapper .parrafo {
    color: #878a87;
    font-family: "Red Hat Display", sans-serif;
    font-size: smaller;
    margin: auto;
    text-align: center;
    -webkit-padding-after: 3.5%;
            padding-block-end: 3.5%; }
  .principal-wrapper .header-wrapper .button-wrapper {
    display: flex;
    width: 20%;
    justify-content: center;
    margin-right: 2%;
    float: right; }

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected {
  font-size: large;
  font-family: "Red Hat Display";
  color: green;
  background: yellowgreen;
  inline-size: 0px; }
  .MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected .MuiTab-wrapper {
    font-family: "Red Hat Display", sans-serif; }

.MuiTabs-flexContainer .MuiTab-wrapper {
  font-family: "Red Hat Display", sans-serif; }

.MuiTabs-scroller .PrivateTabIndicator-colorPrimary-2 {
  background-color: #09f900; }

.contenedor-principal {
  padding: 5% 15%; }
  .contenedor-principal h2 {
    color: green;
    font-family: "Red Hat Display", sans-serif;
    font-size: x-large;
    margin: auto;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
    background: #f3f3f3; }
  .contenedor-principal p {
    color: #878a87;
    font-family: "Red Hat Display", sans-serif;
    font-size: smaller;
    margin: auto;
    text-align: center;
    -webkit-padding-after: 20px;
            padding-block-end: 20px; }
  .contenedor-principal .contenedor-interno {
    background: white;
    border: solid 1px #f3f3f3;
    width: 100%;
    max-width: 375px;
    padding: 15px;
    margin: auto; }
    .contenedor-principal .contenedor-interno .card .card-header {
      border-radius: 0px;
      padding: 0px; }

.loading-info-dash {
  padding-top: 3rem;
  width: 280px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: large;
  font-family: "Red Hat Display"; }

.datatable-rowexpansion-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.datatable-rowexpansion-demo .orders-subtable {
  padding: 1rem; }

.orders-subtable {
  width: 20%;
  margin-left: 48%;
  -webkit-margin-after: 0.1%;
          margin-block-end: 0.1%; }
  .orders-subtable h4 {
    font-size: 13px;
    font-family: sans-serif; }
  .orders-subtable .p-datatable-thead > tr > th {
    background: #e4d0d0; }
  .orders-subtable .p-datatable-tbody > tr > td {
    background: #d0e4df; }

.observacion {
  min-width: 500px; }

.datatable-crud-demo
.card
.claseTablas
.p-datatable-scrollable-wrapper
.p-datatable-scrollable-view
.p-datatable-scrollable-body
.p-datatable-scrollable-body-table
.p-datatable-tbody
tr
.observacion {
  font-size: 70%; }

.Dialog-pdfOportunidad .tableEmpresa {
  -webkit-margin-after: 20px;
          margin-block-end: 20px; }
  .Dialog-pdfOportunidad .tableEmpresa .MuiTableRow-head {
    background-color: green; }
    .Dialog-pdfOportunidad .tableEmpresa .MuiTableRow-head .MuiTableCell-head {
      color: #fff;
      text-align: center;
      padding: 2px;
      font-family: "Red Hat Display", Sans-serif;
      font-size: 16px;
      font-weight: 500; }
  .Dialog-pdfOportunidad .tableEmpresa .MuiTableBody-root {
    background-color: #fff; }
    .Dialog-pdfOportunidad .tableEmpresa .MuiTableBody-root .MuiTableCell-body {
      color: #000;
      text-align: center;
      padding: 2px;
      font-family: "Red Hat Display", Sans-serif;
      font-size: 14px;
      font-weight: 500; }

.Dialog-pdfOportunidad div .button-wrapper {
  background-color: #085e14;
  color: #fff;
  width: 48%;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.Dialog-pdfOportunidad div .button-wrapper:hover {
  background-color: #fff;
  border: 1px solid #085e14;
  color: #085e14;
  width: 48%;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 19px;
  border-radius: 5px;
  font-family: "Red Hat Display", Sans-serif; }

.messageOportunidad {
  background-color: #f3f3f3;
  align-items: center;
  display: flow-root;
  text-align: center;
  -webkit-transform: translateX(1%) translateY(100%);
          transform: translateX(1%) translateY(100%);
  position: static;
  padding: 2%;
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  color: #23682d;
  font-family: "Red Hat Display", sans-serif;
  inset: auto; }

.messageOportunidad .alert.success {
  color: #085e14;
  background-color: #d4edda;
  border-color: #c3e6cb;
  text-align: center;
  padding: 10px;
  width: 100%; }

.messageOportunidad .alert.danger {
  color: #ac0f1e;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  text-align: center;
  padding: 10px;
  width: 100%; }

.messageOportunidad .buttonFinalizar {
  background-color: #085e14;
  color: #fff;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: auto;
  padding: 13px 30px;
  margin-left: 3px; }

.messageOportunidad .buttonFinalizar:hover {
  background-color: #95c21d;
  color: #085e14;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: auto;
  padding: 13px 30px; }

.messageOportunidad {
  width: 60%;
  padding: 1%;
  background: #fbfbfb;
  border-radius: 5px; }
  .messageOportunidad .alertOportunidadDanger {
    color: #ac0f1e;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 1%;
    margin: auto;
    -webkit-margin-after: 1%;
            margin-block-end: 1%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .messageOportunidad .alertOportunidadDanger .MuiAlert-message {
      font-size: 20px;
      text-align: center;
      font-family: "Red Hat Display", sans-serif; }
  .messageOportunidad .alertOportunidadSuccess {
    color: #085e14;
    background-color: #d4edda;
    border-color: #c3e6cb;
    margin: auto;
    padding: 1%;
    margin: auto;
    -webkit-margin-after: 2px;
            margin-block-end: 2px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .messageOportunidad .alertOportunidadSuccess .MuiAlert-message {
      font-size: 20px;
      text-align: center;
      font-family: "Red Hat Display", sans-serif; }

.pdf-component .pdf-doc {
  width: 100vw;
  height: 100vh; }

.section-container .field-container .instruccion2 .button-Update {
  width: 80%;
  background-color: #ffffff;
  padding: 4px 0px;
  border: 1px solid #008a17;
  border-radius: 10px;
  display: block;
  margin: auto;
  -webkit-margin-after: 2%;
          margin-block-end: 2%; }
  .section-container .field-container .instruccion2 .button-Update .parrafo-Update {
    font-size: medium;
    font-family: "Red Hat Display";
    color: #008a17;
    text-align: center; }
  .section-container .field-container .instruccion2 .button-Update .instruccion2 {
    width: 18%;
    background-color: #006711;
    padding: 6px 7px;
    border: 1px solid #008a17;
    border-radius: 5px;
    font-size: large;
    font-family: "Red Hat Display";
    font-weight: 500;
    color: #ffffff;
    display: block;
    margin: auto;
    text-transform: inherit; }

.deportes-main {
  color: #767676; }
  .deportes-main .field-container h3 {
    color: #085e14; }
  .deportes-main .rs-100 {
    margin-left: 1%; }
  .deportes-main .AceptarJuramento {
    display: flex;
    align-items: center; }
    .deportes-main .AceptarJuramento .label-name {
      display: none; }
  .deportes-main .subtitle {
    margin-bottom: 1rem; }
  .deportes-main .main-title {
    color: #085e14; }
  .deportes-main div.card {
    margin: 1rem;
    padding: 1rem;
    background-color: #fff; }
  .deportes-main .deportes-container {
    margin: 1rem;
    margin-top: 0; }
  .deportes-main .deportes-container h3 {
    margin-top: 0; }
  .deportes-main .btn-select {
    margin: 0 auto; }
  .deportes-main .btn-container {
    width: 100%;
    display: flex;
    justify-content: center; }
    .deportes-main .btn-container .btn-title {
      margin: 15px;
      border: 2px solid #000;
      border-right-color: #000;
      border-radius: 10px;
      background: rgba(149, 194, 29, 0.15);
      border: 4px solid #95c21d;
      border-right-color: #95c21d; }
      .deportes-main .btn-container .btn-title span {
        color: #085e14;
        font-weight: 700;
        font-size: 1rem;
        font-family: "Red Hat Display", Sans-serif; }
      .deportes-main .btn-container .btn-title img {
        max-width: 80px; }
      .deportes-main .btn-container .btn-title:hover {
        border-color: #085e14;
        background: rgba(149, 194, 29, 0.5);
        border-right-color: #085e14; }

.form-control div .MuiFormHelperText-root {
  color: red;
  margin: 0px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Red Hat Display", sans-serif; }

.deportes-main .btn-container .btn-title:disabled {
  margin: 15px;
  border: 2px solid #000;
  border-right-color: #000;
  border-radius: 10px;
  background: #95c21d;
  border: 4px solid #95c21d;
  border-right-color: #95c21d; }

.firmaTratamiento .section-container .field-container h3 {
  font-size: medium;
  text-align: center; }

.firmaTratamiento .section-container .field-container .spacerJuramento .label-name {
  float: left;
  color: #085e14;
  font-weight: 500;
  font-size: small;
  text-align: justify;
  padding: 2%; }

.firmaTratamiento .section-container .field-container .estiloTrata div .checkHabeas .MuiFormControlLabel-root .MuiTypography-root .datosAutorizo .MuiLink-root {
  color: #085e14;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }

.container .deportes-container .card .btn-container {
  width: 57%;
  height: 22px;
  display: block;
  justify-content: center;
  -webkit-transform: translate(70%, 490%);
          transform: translate(70%, 490%); }
  .container .deportes-container .card .btn-container .card-image {
    height: 133px; }

.form-container .dateFecha .MuiFormControl-root.datepicker {
  margin-top: 0px; }

.loading-wrapper-depor {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  padding: 3rem;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 2rem;
  -webkit-transform: translate(0%, 10%);
          transform: translate(0%, 10%);
  align-items: center; }
  .loading-wrapper-depor div {
    width: 40%;
    align-items: center;
    margin: auto; }

.deportes-container .card .subtitle span .llevaNegrita {
  font-weight: bold; }

.admin {
  width: 95%;
  margin: 0 auto;
  font-family: "Red Hat Display", sans-serif; }
  .admin .Content-form .Content-body div .container-form-bandeja .main-wrapper {
    max-height: 2000px; }
  .admin h1 {
    margin-top: 1rem; }

.p-mt-3 {
  width: 98%;
  float: right; }

.container-form-bandeja {
  width: 98%;
  margin: auto;
  padding: 2% 0%;
  background: #fff; }
  .container-form-bandeja .auth {
    max-height: none;
    overflow: unset; }

.container-form-anexos {
  width: 98%;
  margin: auto;
  -webkit-margin-after: 0%;
          margin-block-end: 0%;
  display: flow-root; }
  .container-form-anexos .tableSeeAnexo .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiButton-root {
    color: green;
    text-align: left; }
  .container-form-anexos .tableSeeAnexo .MuiTable-root .MuiTableBody-root .MuiTableCell-body {
    text-align: left; }
    .container-form-anexos .tableSeeAnexo .MuiTable-root .MuiTableBody-root .MuiTableCell-body .MuiButton-root {
      color: green; }
  .container-form-anexos .table-Container {
    width: 100%; }
  .container-form-anexos .table-Container-2 {
    width: 30%;
    float: left; }
    @media only screen and (max-width: 780px) {
      .container-form-anexos .table-Container-2 {
        width: 100%;
        -webkit-margin-after: 2%;
                margin-block-end: 2%; } }
  .container-form-anexos .container-anexos-form {
    width: 68%;
    float: right;
    margin: 1%; }
    @media only screen and (max-width: 780px) {
      .container-form-anexos .container-anexos-form {
        width: 100%;
        -webkit-margin-after: 1%;
                margin-block-end: 1%; } }
    .container-form-anexos .container-anexos-form .modalSeeAdjuntos .Anexo {
      display: flex;
      -webkit-margin-after: 1%;
              margin-block-end: 1%; }
      .container-form-anexos .container-anexos-form .modalSeeAdjuntos .Anexo .objAnexo {
        margin: 1%;
        max-width: 98%;
        height: 100vh; }
      .container-form-anexos .container-anexos-form .modalSeeAdjuntos .Anexo .objAnexoPdf {
        margin: 1%;
        height: 100vh;
        width: 98%; }
    .container-form-anexos .container-anexos-form .MuiButtonGroup-root {
      border-radius: 4px;
      width: -webkit-min-content;
      width: min-content;
      margin: auto;
      display: flex; }
      .container-form-anexos .container-anexos-form .MuiButtonGroup-root .buttonCloseAnexo {
        background-color: #fca000;
        border-radius: 20px;
        color: #fff;
        font-weight: bolder; }
      .container-form-anexos .container-anexos-form .MuiButtonGroup-root .buttonCloseAnexo:hover {
        background-color: #fff;
        border-radius: 20px;
        border: solid 1px #fca000;
        color: #fca000; }

.Encabezado-ra {
  position: static;
  margin: 0 auto;
  -webkit-margin-after: 1%;
          margin-block-end: 1%;
  color: #fff;
  font-weight: bold;
  font-size: xxx-large;
  background: #fca000;
  background: linear-gradient(90deg, #fca000 0%, #ffba42 35%, #fed184 100%); }

.Content-form .Content-nav {
  width: 100%;
  margin: auto;
  -webkit-margin-after: 1%;
          margin-block-end: 1%;
  float: right; }
  .Content-form .Content-nav .button-nav-bandeja {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: flex-end;
    margin-right: 1%;
    float: right; }
    .Content-form .Content-nav .button-nav-bandeja .MuiButton-outlinedPrimary {
      background-color: #fca000;
      border-color: #fca000;
      color: #fff;
      padding: 8px 16px;
      text-transform: capitalize; }
    .Content-form .Content-nav .button-nav-bandeja .MuiButton-outlinedPrimary:hover {
      background-color: #fff;
      border-color: #fca000;
      color: #fca000;
      padding: 8px 16px;
      text-transform: capitalize; }

.Content-form .Content-body {
  width: 100%;
  margin: auto;
  display: flow-root; }

@media only screen and (max-width: 780px) {
  .Content-form .Content-nav {
    width: 100%;
    background-color: #fff;
    -webkit-margin-after: 2%;
            margin-block-end: 2%; } }

@media only screen and (max-width: 780px) {
  .Content-form .Content-body {
    width: 100%;
    background-color: #fff; } }

.Content-form-reports .Content-nav {
  width: 100%;
  margin: auto;
  -webkit-margin-after: 1%;
          margin-block-end: 1%;
  float: right; }
  .Content-form-reports .Content-nav .people-form .section-container {
    background-color: #fff; }
    .Content-form-reports .Content-nav .people-form .section-container .field-container .material-field .button-AddRecurso .MuiButton-textPrimary {
      width: 80%;
      display: block;
      margin-top: 4%;
      font-size: larger;
      text-transform: none;
      border-radius: 5px;
      font-family: "Red Hat Display", sans-serif;
      color: white;
      padding: 4% 4%;
      font-weight: 500;
      background: #085e14; }
    .Content-form-reports .Content-nav .people-form .section-container .field-container .material-field .button-AddRecurso .MuiButton-textPrimary:hover {
      width: 80%;
      display: block;
      margin-top: 4%;
      font-size: larger;
      text-transform: none;
      border-radius: 5px;
      font-family: "Red Hat Display", sans-serif;
      padding: 4% 4%;
      font-weight: 500;
      background-color: #fca000;
      border-color: #fca000;
      color: #fff; }
  .Content-form-reports .Content-nav .button-nav-bandeja {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: flex-end;
    margin-right: 1%;
    float: right; }
    .Content-form-reports .Content-nav .button-nav-bandeja .MuiButton-outlinedPrimary {
      background-color: #fca000;
      border-color: #fca000;
      color: #fff;
      padding: 8px 16px;
      text-transform: capitalize; }
    .Content-form-reports .Content-nav .button-nav-bandeja .MuiButton-outlinedPrimary:hover {
      background-color: #fff;
      border-color: #fca000;
      color: #fca000;
      padding: 8px 16px;
      text-transform: capitalize; }

.Content-form-reports .Content-body {
  width: 100%;
  margin: auto;
  display: flow-root; }

@media only screen and (max-width: 780px) {
  .Content-form-reports .Content-nav {
    width: 100%;
    background-color: #fff;
    -webkit-margin-after: 2%;
            margin-block-end: 2%; } }

@media only screen and (max-width: 780px) {
  .Content-form-reports .Content-body {
    width: 100%;
    background-color: #fff; } }

.MuiButtonGroup-root .MuiButtonBase-root {
  width: 100%; }

.p-datatable.p-datatable-gridlines .p-datatable-header .table-header h5 {
  font-size: x-large;
  margin: auto;
  text-align: center;
  color: #0eae0e;
  text-transform: capitalize; }

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
  word-break: break-word;
  font-family: "Red Hat Display", sans-serif;
  padding: 2px 8px;
  color: "#000"; }
  @media only screen and (max-width: 780px) {
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
      font-size: 12px;
      padding: 2px; } }

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px;
  word-break: break-word;
  font-family: "Red Hat Display", sans-serif;
  padding: 2px 8px; }
  @media only screen and (max-width: 780px) {
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
      font-size: 12px;
      padding: 2px; } }

.Content-form .Content-body .devoluciones-container {
  width: 95%;
  background: #fff;
  margin: auto;
  margin-top: 1%; }
  .Content-form .Content-body .devoluciones-container .buttonSeeDevoluciones .title-devoluciones {
    color: #000;
    font-size: large;
    padding: 0px 9px;
    font-weight: bold;
    font-family: "Red Hat Display";
    text-transform: capitalize; }
  .Content-form .Content-body .devoluciones-container .form-wrapper .form-container .field-container {
    margin-bottom: 0px; }
    .Content-form .Content-body .devoluciones-container .form-wrapper .form-container .field-container .material-field {
      margin-bottom: 0px; }
      .Content-form .Content-body .devoluciones-container .form-wrapper .form-container .field-container .material-field .section-container {
        box-shadow: none;
        padding: 0 1rem; }
  .Content-form .Content-body .devoluciones-container .MuiButton-iconSizeMedium > *:first-child {
    font-size: xx-large;
    color: black; }
  .Content-form .Content-body .devoluciones-container .MuiButton-startIcon {
    display: inherit;
    margin: 0px; }

.Content-form .Content-body .anulaciones-container {
  width: 95%;
  background: #fff;
  margin: auto;
  margin-top: 1%; }
  .Content-form .Content-body .anulaciones-container .form-wrapper .form-container .field-container {
    margin-bottom: 0px; }
    .Content-form .Content-body .anulaciones-container .form-wrapper .form-container .field-container .material-field {
      margin-bottom: 0px; }
      .Content-form .Content-body .anulaciones-container .form-wrapper .form-container .field-container .material-field .section-container {
        box-shadow: none;
        padding: 0 1rem; }

.buttons-container {
  margin-top: 1%;
  -webkit-margin-after: 7%;
          margin-block-end: 7%;
  background: #fff; }
  .buttons-container .navega-buttons {
    float: left;
    width: auto; }
  .buttons-container .button-wrapper {
    float: right;
    width: auto;
    margin-top: 0%; }
  .buttons-container .MuiButtonGroup-root .MuiButton-outlined {
    border: none;
    padding: 10px 20px; }
  .buttons-container .MuiButtonGroup-root .MuiButton-root {
    background-color: #fca000;
    border: 1px solid #fca000;
    border-radius: 10px;
    margin: auto 1% auto auto;
    text-transform: capitalize; }
    .buttons-container .MuiButtonGroup-root .MuiButton-root .MuiButton-label {
      color: #fff;
      font-family: "Red Hat Display", sans-serif; }
  .buttons-container .MuiButtonGroup-root .MuiButton-root:hover {
    background-color: #fff;
    border: 1px solid #fca000; }
    .buttons-container .MuiButtonGroup-root .MuiButton-root:hover .MuiButton-label {
      color: #fca000; }

.modal-confirmation .MuiDialog-container .MuiDialog-paperWidthSm {
  width: auto;
  max-width: calc(100% - 20%); }

.modal-confirmation .MuiDialog-paperScrollPaper {
  display: flex;
  min-height: calc(100% - 60%); }
  .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogContent-root .MuiDialogContentText-root {
    font-size: large;
    font-family: "Red Hat Display", sans-serif;
    color: #13531e; }
  .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-outlined {
    border: none;
    padding: 10px 20px; }
  .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root {
    background-color: #fca000;
    border: 1px solid #fca000;
    border-radius: 10px;
    margin: auto 1% auto auto; }
    .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root .MuiButton-label {
      color: #fff;
      font-family: "Red Hat Display", sans-serif; }
  .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root:hover {
    background-color: #ffffff;
    border: 1px solid #fca000; }
    .modal-confirmation .MuiDialog-paperScrollPaper .MuiDialogActions-root .MuiButtonGroup-root .MuiButton-root:hover .MuiButton-label {
      color: #fca000; }

.messageSucess {
  padding: 1%;
  width: auto;
  background-color: #fff;
  border-radius: 10px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }
  .messageSucess .MuiPaper-root {
    -webkit-margin-after: 1%;
            margin-block-end: 1%; }

.modal-history .MuiDialogContent-root {
  background: #297d0014; }

.modal-history .row-history {
  background: #fff;
  margin-top: 2%;
  box-shadow: 0px 2px 1px -1px #00000029, 0px 1px 1px 0px #ece6e60d, 0px 1px 3px 0px #00000024;
  padding: 0px 20px; }
  .modal-history .row-history h3 {
    padding: 0px;
    margin: 0px;
    font-size: medium;
    font-family: "Red Hat Display"; }
    .modal-history .row-history h3 p {
      width: -webkit-min-content;
      width: min-content;
      margin: 0px;
      display: contents;
      font-weight: 500; }
  .modal-history .row-history div h2 {
    margin: 0px;
    font-size: medium;
    font-family: "Red Hat Display";
    font-weight: 500; }
  .modal-history .row-history div h3 {
    padding: 0px 15px;
    margin: 0px; }
    .modal-history .row-history div h3 p {
      width: -webkit-min-content;
      width: min-content;
      margin: 0px;
      display: contents;
      font-weight: 500; }

.modal-history .MuiButtonGroup-root .MuiButton-outlined {
  border: none;
  padding: 10px 20px; }

.modal-history .MuiButtonGroup-root .MuiButton-root {
  background-color: #fca000;
  border: 1px solid #fca000;
  border-radius: 10px;
  margin: auto 1% auto auto; }
  .modal-history .MuiButtonGroup-root .MuiButton-root .MuiButton-label {
    color: #fff;
    font-family: "Red Hat Display", sans-serif; }

.modal-history .MuiButtonGroup-root .MuiButton-root:hover {
  background-color: #ffffff;
  border: 1px solid #fca000; }
  .modal-history .MuiButtonGroup-root .MuiButton-root:hover .MuiButton-label {
    color: #fca000; }

.modal-history .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
  background: #fff; }
  .modal-history .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .section-container {
    background: #fff;
    margin-top: 2%;
    box-shadow: 0px 2px 1px -1px #00000029, 0px 1px 1px 0px #ece6e60d, 0px 1px 3px 0px #00000024;
    padding: 0px 20px; }

.MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .formProcessNum {
  width: 50%;
  float: left; }

.MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .data-anulation-form {
  width: 50%;
  float: right;
  font-size: smaller;
  color: #1f1d1d;
  font-family: "Red Hat Display";
  padding: 1%; }
  .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .data-anulation-form h2 {
    color: #085e14;
    text-align: center;
    font-weight: 600; }
  .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .data-anulation-form h3 {
    margin: auto; }
    .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .data-anulation-form h3 p {
      display: initial;
      margin-right: 7px; }

.MuiDialog-container .MuiDialog-paper .MuiDialogContent-root .component-anulation .anulaciones-container .people-form .field-container .material-field .section-container {
  margin-top: 0%; }

.datosPorValidar {
  padding: 2%;
  display: grid;
  width: inherit;
  margin: auto;
  background-color: #fff; }
  .datosPorValidar p {
    background-color: #fff;
    color: #154c1e;
    font-size: xx-large;
    text-align: center;
    -webkit-margin-after: 3%;
            margin-block-end: 3%;
    border-radius: 4px;
    padding: 4%;
    box-shadow: 0px 2px 1px -1px #00000029, 0px 1px 1px 0px #ece6e60d, 0px 1px 3px 0px #00000024; }
  .datosPorValidar img {
    margin: auto; }

.tipos-sociedad .p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  text-transform: uppercase; }

.tipos-sociedad .p-autocomplete-dd .p-autocomplete-input {
  text-transform: capitalize; }

.button-download {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: flex-end;
  margin-right: 1%;
  float: right; }
  .button-download .MuiButtonBase-root {
    background-color: #fca000;
    border-color: #fca000;
    color: #fff;
    padding: 8px 16px;
    text-transform: none; }
  .button-download .MuiButtonBase-root:hover {
    background-color: #fff;
    border-color: #fca000;
    color: #fca000;
    padding: 8px 16px;
    text-transform: none; }

.people-inform-table .p-datatable .p-datatable-wrapper .p-datatable-tbody {
  text-transform: uppercase; }

.main-wrapper .messageSucess .buttonFinalizar {
  background-color: #fca000;
  color: #fff;
  font-family: "Red Hat Display";
  border: solid 1px;
  font-weight: 500;
  width: 20%;
  padding: 10px;
  margin-left: 3px; }

.main-wrapper .messageSucess .buttonFinalizar:hover {
  background-color: #fff;
  border: solid 1px;
  color: #fca000;
  font-family: "Red Hat Display";
  font-weight: 500;
  width: 20%;
  margin-left: 3px;
  padding: 10px; }

.modal-anexos-table {
  width: 80%;
  margin: auto; }
  .modal-anexos-table .MuiDialog-container {
    height: auto; }
    .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm {
      max-width: 100%; }
      .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogTitle-root {
        padding: 0px; }
        .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogTitle-root h2 {
          text-align: center;
          font-weight: bold;
          font-size: x-large;
          color: #085e14; }
      .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .container-form-anexos {
        width: 98%;
        margin: auto;
        -webkit-margin-after: 1%;
                margin-block-end: 1%;
        display: block; }
      .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .modalSeeAdjuntos {
        float: right;
        width: 68%; }
        .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .modalSeeAdjuntos .Anexo .objAnexo {
          min-height: calc(450px - 80px); }
      .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiButtonGroup-root {
        display: flex;
        border-radius: 10px;
        float: right;
        width: 70%;
        margin-left: 96%;
        margin-top: 1%; }
        .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiButtonGroup-root .MuiButtonBase-root {
          background-color: #fca000;
          color: #fff;
          font-family: "Red Hat Display";
          border: solid 1px;
          font-weight: 500;
          padding: 10px; }
        .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiButtonGroup-root .MuiButtonBase-root:hover {
          background-color: #fff;
          color: #fca000; }
      .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root .anulation-devolution {
        width: auto; }
        .modal-anexos-table .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root .anulation-devolution .MuiButton-root {
          margin-left: 1%;
          border-radius: 10px; }
  .modal-anexos-table .MuiDialog-scrollPaper {
    display: contents; }
  .modal-anexos-table .container-form-anexos .table-Container-2 {
    width: 30%;
    float: left; }

.modal-anexos-table .MuiDialog-container .MuiPaper-root .button-closed-modal {
  display: inline;
  float: right;
  width: 3%; }
  .modal-anexos-table .MuiDialog-container .MuiPaper-root .button-closed-modal .MuiButtonBase-root {
    background-color: #fc5400;
    color: #fff;
    font-family: "Red Hat Display";
    border: solid 1px;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px; }
  .modal-anexos-table .MuiDialog-container .MuiPaper-root .button-closed-modal .MuiButtonBase-root:hover {
    background-color: #fff;
    color: #fc5400; }

.p-datatable {
  overflow-x: scroll; }

.button-group-table {
  margin: auto; }
  .button-group-table .button-table {
    width: 24%;
    border-radius: 2rem;
    display: table-cell;
    margin-right: 1px;
    padding: 1px 0px; }
  .button-group-table .btn-asign {
    background-color: #c2004e; }
  .button-group-table .btn-see {
    background-color: #034c0b; }
  .button-group-table .btn-edit {
    background-color: #0048ff; }
  .button-group-table .btn-return {
    background-color: #b300ff; }
  .button-group-table .btn-close {
    background-color: #f8a100; }


.side-nav-wrapper {
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  width: 5rem;
  height: 100vh;
  background-color: #23232e;
  transition: width 200ms ease;
  z-index: 2; }
  .side-nav-wrapper .side-nav-nar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%; }
    .side-nav-wrapper .side-nav-nar .logo {
      text-align: center;
      color: #ececec;
      background-color: #141418;
      text-transform: uppercase;
      letter-spacing: 0.3ch;
      width: 100%; }
      .side-nav-wrapper .side-nav-nar .logo .link-text {
        margin-left: 1rem;
        font-weight: bold;
        font-size: 1.5rem; }
      .side-nav-wrapper .side-nav-nar .logo .nav-icon {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        transition: -webkit-transform 600ms;
        transition: transform 600ms;
        transition: transform 600ms, -webkit-transform 600ms; }
    .side-nav-wrapper .side-nav-nar:hover .logo .nav-icon {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg); }
  .side-nav-wrapper:hover {
    width: 20rem; }
    .side-nav-wrapper:hover .side-nav-nar .link-text {
      display: block; }
  @media only screen and (min-width: 900px) {
    .side-nav-wrapper {
      top: 0;
      width: 5rem;
      height: 100vh; }
      .side-nav-wrapper:hover {
        width: 20rem; }
        .side-nav-wrapper:hover .link-text {
          display: inline; } }
  @media only screen and (max-width: 780px) {
    .side-nav-wrapper {
      bottom: 0;
      width: 100vw;
      height: 5rem;
      z-index: 2; }
      .side-nav-wrapper .logo {
        display: none; }
      .side-nav-wrapper .side-nav-nar {
        flex-direction: row;
        justify-content: space-around; }
      .side-nav-wrapper .sidebar-nav-link {
        justify-content: center; } }

.styled-nav-item {
  width: 100%;
  /* width must be same size as NavBar to center */
  text-align: center;
  /* Aligns <a> inside of NavIcon div */ }
  .styled-nav-item:hover > .submenu {
    display: block;
    transition: all 2s linear; }
  .styled-nav-item .nav-link {
    padding: 0; }
  .styled-nav-item:hover {
    background: #141418;
    color: #ff7eee; }
  .styled-nav-item:last-child {
    margin-top: auto; }
  .styled-nav-item .sidebar-nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 16rem;
    color: #6649b8;
    text-decoration: none;
    -webkit-filter: grayscale(100%) opacity(0.7);
            filter: grayscale(100%) opacity(0.7);
    transition: 600ms; }
    .styled-nav-item .sidebar-nav-link:hover {
      text-decoration: none;
      -webkit-filter: none;
              filter: none; }
    .styled-nav-item .sidebar-nav-link .link-text {
      display: none;
      margin-left: 0;
      font-weight: 500;
      font-size: 1.2rem; }
    .styled-nav-item .sidebar-nav-link .nav-icon, .styled-nav-item .sidebar-nav-link .nav-image {
      min-width: 2rem;
      margin: 0 1.5rem;
      height: 2rem;
      color: #6649b8;
      transition: 600ms; }
      .styled-nav-item .sidebar-nav-link .nav-icon:hover, .styled-nav-item .sidebar-nav-link .nav-image:hover {
        color: #6649b8; }
    @media only screen and (max-width: 780px) {
      .styled-nav-item .sidebar-nav-link {
        width: auto; } }
  @media only screen and (max-width: 780px) {
    .styled-nav-item {
      width: auto; } }
  .styled-nav-item .submenu {
    display: none;
    transition: all 2s linear;
    padding-left: 0; }
    @media only screen and (max-width: 780px) {
      .styled-nav-item .submenu {
        display: none; } }
    .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link {
      height: 3rem;
      border-bottom: 1px solid #23232e; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .link-text {
        font-size: 1rem; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .nav-icon {
        max-width: 1.5rem;
        min-width: 1.5rem; }
    .styled-nav-item .submenu .submenu-nav-link {
      width: 20rem;
      background: #574b90;
      color: #eee;
      text-align: left;
      border-bottom: 1px solid #000;
      font-weight: 500;
      padding: 10px 0 10px 25px;
      -webkit-filter: grayscale(30%) opacity(0.7);
              filter: grayscale(30%) opacity(0.7); }
      .styled-nav-item .submenu .submenu-nav-link:hover {
        -webkit-filter: grayscale(0%) opacity(1);
                filter: grayscale(0%) opacity(1); }

